import React from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "components";
import {
	Dashboard,
	Login,
	RecoverPassword,
	Staff,
	Logs,
	Settings,
	AcceptInvite,
	Pages,
	EmailTemplates,
	PageDetail,
	EmailDetail,
	Error500,
	Businesses,
	BusinessDetail,
	ContractDetail,
	ContractTypes,
	Orders,
	OrderDetail,
	OrderRoute,
	Vehicles,
	Grids,
	Shifts,
} from "screens";

export const offlinePages = ["/login", "/register", "/accept-invite", "/recover-password", "/confirm"];

export class Routes extends React.Component<any, any> {
	shouldComponentUpdate(nextProps: any) {
		const { user, token } = this.props;
		const isLoggedIn = Boolean(user && token);
		const willBeLoggedIn = Boolean(nextProps.user && nextProps.token);

		return isLoggedIn !== willBeLoggedIn;
	}

	componentDidUpdate() {
		const elem = document.getElementById("app_content");

		if (elem) {
			elem.scrollTop = 0;
		}
	}

	render() {
		const { token, router, user } = this.props;
		const { location } = router;

		if (token && user && user.role !== "marketing") {
			location.pathname = "/dashboard";
		} else if (token && user && user.role === "marketing") {
			location.pathname = "/library";
		} else {
			location.pathname = "/login";
		}

		const path = location.pathname.split("/")[1];
		const isAdmin = user?.role !== "marketing";
		
		if (offlinePages.indexOf(location.pathname) !== -1 || offlinePages.indexOf(`/${path}`) !== -1) {
			return (
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/recover-password" component={RecoverPassword} />
					<Route exact path="/recover-password/:id/:code" component={RecoverPassword} />
					<Route exact path="/accept-invite/:id/:code" component={AcceptInvite} />
					<Route exact path="/confirm/:email/:code" component={() => <h1>Confirm Account</h1>} />
					<Route exact path="/500" component={Error500} />
					<Redirect to="/login" />
				</Switch>
			);
		}

		return (
			<Switch>
				<PrivateRoute exact path="/dashboard" component={Dashboard} />
				<PrivateRoute exact path="/businesses" component={Businesses} />
				<PrivateRoute exact path="/businesses/:id" component={BusinessDetail} />
				<PrivateRoute exact path="/businesses/:id/contracts/:contractId" component={ContractDetail} />
				<PrivateRoute exact path="/orders" component={Orders} />
				<PrivateRoute exact path="/orders/:id" component={OrderDetail} />
				<PrivateRoute exact path="/orders/:id/route" component={OrderRoute} />
				{(isAdmin && <PrivateRoute exact path="/staff" component={Staff} />) || null}
				<PrivateRoute exact path="/settings" component={Settings} />
				<PrivateRoute exact path="/settings/pages" component={Pages} />
				<PrivateRoute exact path="/settings/pages/:id" component={PageDetail} />
				<PrivateRoute exact path="/settings/email-templates" component={EmailTemplates} />
				<PrivateRoute exact path="/settings/email-templates/:id" component={EmailDetail} />
				<PrivateRoute exact path="/settings/vehicles" component={Vehicles} />
				<PrivateRoute exact path="/settings/grids" component={Grids} />
				<PrivateRoute exact path="/settings/shifts" component={Shifts} />
				<PrivateRoute exact path="/settings/contract-types" component={ContractTypes} />
				<PrivateRoute exact path="/logs" component={Logs} />
				<PrivateRoute exact path="/500" component={Error500} />
				{isAdmin ? <Redirect to="/dashboard" /> : <Redirect to="/library" />}
			</Switch>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	user: state.user,
	token: state.token,
});

export default connect(mapStateToProps)(Routes);
