/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle, Polygon } from "react-google-maps";

let controlRef = React.createRef();

export const Map = withScriptjs(
	withGoogleMap((props) => {
		const zoom = 12;
		return (
			<GoogleMap
				zoom={props.zoom || zoom}
				defaultOptions={{
					streetViewControl: false,
					scaleControl: false,
					mapTypeControl: false,
					panControl: true,
					zoomControl: true,
					rotateControl: true,
					fullscreenControl: true,
					gestureHandling: "cooperative",
					streatViewControl: false
				}}
				center={props.center ?? { lat: 38.72152, lng: -9.142161 }}
				defaultCenter={{
					lat:
						props.parent &&
							props.parent.state.location &&
							props.parent.state.location.coordinates &&
							props.parent.state.location.coordinates[1] != null
							? props.parent.state.location.coordinates[1]
							: props.defaultCenter
								? props.defaultCenter.lat
								: 38.72152,
					lng:
						props.parent &&
							props.parent.state.location &&
							props.parent.state.location.coordinates &&
							props.parent.state.location.coordinates[0] != null
							? props.parent.state.location.coordinates[0]
							: props.defaultCenter
								? props.defaultCenter.lng
								: -9.142161
				}}
				onClick={(e) => {
					if (props.order) {
						if (props.parent.state.newAddressSelected) {
							props.parent.setState(
								(prevState) => ({
									newAddress: {
										...prevState.newAddress,
										location: {
											lat: Number.parseFloat(e.latLng.lat()),
											lng: Number.parseFloat(e.latLng.lng())
										}
									},
									hasUnsavedFields: true
								}),
								() => props.parent.validateAddress()
							);
						} else {
							props.parent.setState({
								newCoords: {
									lat: Number.parseFloat(e.latLng.lat()),
									lng: Number.parseFloat(e.latLng.lng())
								},
								hasUnsavedFields: true
							});
						}
					} else if (props.parent) {
						props.parent.setState({
							basePoint: {
								type: "Point",
								coordinates: [Number.parseFloat(e.latLng.lng()), Number.parseFloat(e.latLng.lat())]
							},
							hasUnsavedFields: true
						});
					}
				}}
			>
				{Boolean(props.location.lat) && Boolean(props.location.lng) && (
					<Marker
						position={{
							lat: props.location.lat,
							lng: props.location.lng
						}}
					/>
				)}
				{(Boolean(props.parent) &&
					props.pinSetted &&
					!props.isEditing &&
					!props.isCreating &&
					props.locations.map((loc, i) => {
						if (loc.type === "Polygon" && loc.coordinates && loc.coordinates.length > 0) {
							return (
								<Polygon
									path={loc.coordinates.map((figure) => ({
										lat: Number(figure.lat),
										lng: Number(figure.lng)
									}))}
									key={i}
									options={{
										fillColor: "#73a2bf",
										fillOpacity: 0.4,
										strokeColor: "#73a2bf",
										strokeOpacity: 1,
										strokeWeight: 1
									}}
									onClick={(e) => {
										const location = {
											...props.parent.state.location,
											coordinates: [
												Number.parseFloat(e.latLng.lng()),
												Number.parseFloat(e.latLng.lat())
											]
										};
										props.parent.setState({ location, pinSetted: true, hasUnsavedFields: true });
									}}
								/>
							);
						}
						return (
							<div>
								<Marker
									position={{
										lat: loc.coordinates[0].lat || props.defaultCenter.lat,
										lng: loc.coordinates[0].lng || props.defaultCenter.lng
									}}
									key={i}
								>
									<Circle
										visible
										options={{
											strokeColor: "#009dff",
											strokeOpacity: 0.2,
											fillColor: "#009dff"
										}}
										center={{
											lat: loc.coordinates[0].lat || props.defaultCenter.lat,
											lng: loc.coordinates[0].lng || props.defaultCenter.lng
										}}
										radius={(1 || 0) * 1000}
									/>
								</Marker>
								<Marker
									position={{
										lat: loc.coordinates[0].lat - 1 || props.defaultCenter.lat - 1,
										lng: loc.coordinates[0].lng || props.defaultCenter.lng
									}}
									key={i}
								>
									<Circle
										visible
										options={{
											strokeColor: "#009dff",
											strokeOpacity: 0.2,
											fillColor: "#009dff"
										}}
										center={{
											lat: loc.coordinates[0].lat || props.defaultCenter.lat,
											lng: loc.coordinates[0].lng || props.defaultCenter.lng
										}}
										radius={(20 || 0) * 1000}
									/>
								</Marker>
							</div>
						);
					})) ||
					null}

				{(Boolean(props.parent) &&
					(props.isEditing || props.isCreating) &&
					props.pinSetted &&
					props.parent.state.editingStyle === "radius" && (
						<Marker
							position={{
								lat:
									(props.parent.state.tempRadius &&
										props.parent.state.tempRadius.coordinates &&
										props.parent.state.tempRadius.coordinates.length &&
										props.parent.state.tempRadius.coordinates[0].lat) ||
									props.defaultCenter.lat,
								lng:
									(props.parent.state.tempRadius &&
										props.parent.state.tempRadius.coordinates &&
										props.parent.state.tempRadius.coordinates.length &&
										props.parent.state.tempRadius.coordinates[0].lng) ||
									props.defaultCenter.lng
							}}
						>
							<Circle
								visible
								options={{
									strokeColor: "#009dff",
									strokeOpacity: 0.2,
									fillColor: "#009dff"
								}}
								center={{
									lat:
										(props.parent.state.tempRadius &&
											props.parent.state.tempRadius.coordinates &&
											props.parent.state.tempRadius.coordinates.length &&
											props.parent.state.tempRadius.coordinates[0].lat) ||
										props.defaultCenter.lat,
									lng:
										(props.parent.state.tempRadius &&
											props.parent.state.tempRadius.coordinates &&
											props.parent.state.tempRadius.coordinates.length &&
											props.parent.state.tempRadius.coordinates[0].lng) ||
										props.defaultCenter.lng
								}}
								radius={
									((props.parent.state.tempRadius && Number(props.parent.state.tempRadius.radius)) ||
										0) * 1000
								}
								onClick={(e) => {
									if (props.parent) {
										const tempRadius = {
											...props.parent.state.tempRadius,
											coordinates: [
												{
													lat: Number.parseFloat(e.latLng.lat()),
													lng: Number.parseFloat(e.latLng.lng())
												}
											]
										};
										props.parent.setState({ tempRadius });
									}
								}}
							/>
						</Marker>
					)) ||
					null}

				{(Boolean(props.parent) &&
					(props.isEditing || props.isCreating) &&
					props.pinSetted &&
					props.parent.state.editingStyle === "polygon" && (
						<Polygon
							path={
								props.parent.state.tempPolygon
									? props.parent.state.tempPolygon.coordinates.map((ll) => ({
										lat: Number.parseFloat(ll.lat),
										lng: Number.parseFloat(ll.lng)
									}))
									: []
							}
							key={props.parent.state.locations.length}
							ref={(ref) => {
								controlRef = ref;
							}}
							onMouseUp={() => {
								const paths = controlRef
									.getPath()
									.getArray()
									.map((el) => ({
										lat: Number.parseFloat(el.lat()),
										lng: Number.parseFloat(el.lng())
									}));
								const polygon = JSON.parse(JSON.stringify(props.parent.state.tempPolygon));
								polygon.coordinates = paths;
								props.parent.setState({ tempPolygon: polygon, edited: true });
							}}
							onDragEnd={() => {
								const paths = controlRef
									.getPath()
									.getArray()
									.map((el) => ({
										lat: Number.parseFloat(el.lat()),
										lng: Number.parseFloat(el.lng())
									}));
								const polygon = JSON.parse(JSON.stringify(props.parent.state.tempPolygon));
								polygon.coordinates = paths;
								props.parent.setState({ tempPolygon: polygon, edited: true });
							}}
							options={{
								fillColor: "#009dff",
								fillOpacity: 0.4,
								strokeColor: "#009dff",
								strokeOpacity: 1,
								strokeWeight: 1,
								editable: true,
								draggable: true
							}}
						/>
					)) ||
					null}
			</GoogleMap>
		);
	})
);
