/*
 *
 * EmailTemplates
 *
 */

import React from "react";
import { connect } from "react-redux";
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb, setLoader } from "store/actions";
import { Helmet } from "react-helmet";
import { Icon, Table } from "components";
import { Col, Drawer, Input, notification, Row, Select } from "antd";
import Strings from "utils/strings";

import { API, Endpoints } from "utils/api";

class Grids extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			grids: []
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(""));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences"
						},
						{
							text: Strings.settings.grids,
							icon: "box"
						}
					]
				};
			})
		);

		this.getData();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getData() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.get({
				url: Endpoints.uriGrids()
			});

			if (response.ok) {
				const { grids = [] } = response.data.results || {};
				this.setState({ grids });
			} else {
				notification.error({
					message: Strings.settings.grids,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.grids,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async saveGrid() {
		const { tempGrid } = this.state;
		const { dispatch } = this.props;

		if (!tempGrid) return;

		if (!this.validGrid()) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			const request = tempGrid?._id ? API.put : API.post;
			response = await request({
				url: Endpoints.uriGrids(tempGrid?._id || ""),
				data: {
					name: tempGrid.name
				}
			});

			if (response.ok) {
				await this.getData();
				this.setState({ tempGrid: null, showDrawer: false });

				notification.success({
					message: Strings.settings.grids,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.grids,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.grids,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async patchGrid(grid: any) {
		const { dispatch } = this.props;

		if (!grid?._id) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.patch({
				url: Endpoints.uriGrids(grid._id),
				data: {
					disabled: !grid.disabled
				}
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.grids,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.grids,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.grids,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	async deleteGrid(grid: any) {
		const { dispatch } = this.props;

		if (!grid) return;

		dispatch(setLoader(true));

		let response: any;
		try {
			response = await API.delete({
				url: Endpoints.uriGrids(grid)
			});

			if (response.ok) {
				await this.getData();

				notification.success({
					message: Strings.settings.grids,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5
				});
			} else {
				notification.error({
					message: Strings.settings.grids,
					description: response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5
				});
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.grids,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5
			});
		}

		dispatch(setLoader(false));
	}

	validGrid() {
		const { tempGrid } = this.state;

		if (!tempGrid?.name) {
			notification.error({
				message: Strings.settings.grids,
				description: Strings.settings.nameRequired,
				placement: "bottomRight",
				duration: 5
			});
			return false;
		}

		return true;
	}

	renderTable() {
		const { grids = [] } = this.state;

		return (
			<Table
				title={{
					icon: "box",
					title: Strings.settings.grids
				}}
				data={grids}
				columns={[
					{
						Header: Strings.fields.name,
						id: "grid_name",
						accessor: (row: any) => row.name || "-"
					}
				]}
				fullPage
				isSinglePage
				filterable
				sortable
				noFieldFilter
				add={{
					onClick: () => this.setState({ showDrawer: true })
				}}
				actions={{
					edit: (row: any) => ({
						onClick: () => this.setState({ showDrawer: true, tempGrid: JSON.parse(JSON.stringify(row)) })
					}),
					remove: (row: any) => ({
						onClick: () => this.deleteGrid(row._id)
					}),
					toggle: (row: any) => ({
						onChange: () => this.patchGrid(row),
						value: !row.disabled
					})
				}}
			/>
		);
	}

	renderDrawer() {
		const { showDrawer, tempGrid } = this.state;
		const { mobile } = this.props;

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="box" />
						<p>{tempGrid?._id ? Strings.settings.editGrid : Strings.settings.addGrid}</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveGrid()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showDrawer: false,
									tempGrid: null
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ tempGrid: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		);
	}

	renderDrawerContent() {
		const { tempGrid } = this.state;

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="grid_name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="grid_name"
						placeholder={Strings.fields.name}
						value={tempGrid?.name || ""}
						onChange={(e: any) => {
							const value = e.target.value;

							this.setState((prevState: any) => ({
								tempGrid: {
									...prevState.tempGrid,
									name: value
								}
							}));
						}}
					/>
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.grids}</title>
					<meta name="description" content={Strings.settings.gridsDescription} />
				</Helmet>
				{this.renderTable()}
				{this.renderDrawer()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile
});

export default connect(mapStateToProps)(Grids);
